/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import downloadjs from 'downloadjs'
import { DataGrid } from '@material-ui/data-grid'

import { BACKEND_URL } from '../../../config'
import { headers } from '../../../utils/apiHeaders'
import { GetAdminGuaranteesList } from '../../../admin/guarantees/adminGuarantees.action'
import { useAppDispatch } from '../../../utils/hooks'
import { Button } from '../button/Button'

const GuaranteesTable = ({ guaranteesList, deleteGuaranteeItem, deleteErrorMessage }: any) => {
  const dispatch = useAppDispatch()
  guaranteesList.map((guarantee: any) => (guarantee.id = guarantee.guaranteeNumber))

  const downloadInvoice = ({ guaranteeNumber, invoiceFile }: any) => {
    const fileName = invoiceFile.replace(/^.*[\\/]/, '')
    const requestOptions = {
      method: 'GET',
      headers: headers(),
    }
    fetch(`${BACKEND_URL}/api/guarantee/${guaranteeNumber}/download`, requestOptions)
      .then((response) => response.blob())
      .then((blob: any) => downloadjs(blob, fileName, 'application/octet-stream'))
      .catch((err) => {
        // TODO: Handle error without console
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  const changeStatus = (row: any) => {
    fetch(`${BACKEND_URL}/api/guarantee/${row.guaranteeNumber}`, {
      method: 'PATCH',
      headers: headers(),
      body: JSON.stringify({ guaranteeStatus: 'CONFIRMED' }),
    }).then(() => {
      dispatch(GetAdminGuaranteesList(''))
    })
  }

  const columns = [
    {
      field: 'invoiceFile',
      headerName: 'Invoice file',
      width: 200,
      editable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => (
        <Button className="border-0" onClick={() => downloadInvoice(params.row)}>
          Download file
        </Button>
      ),
    },
    {
      field: 'guaranteeStatus',
      headerName: 'Status',
      width: 150,
      editable: false,
    },
    {
      field: 'button',
      headerName: 'Action',
      width: 150,
      editable: false,
      renderCell: (params: any) => (
        <Button
          className="border-0"
          disabled={params.row.guaranteeStatus === 'CONFIRMED'}
          onClick={() => changeStatus(params.row)}
        >
          Confirm
        </Button>
      ),
    },
    {
      field: 'customerEmail',
      headerName: 'Customer email',
      width: 200,
      editable: false,
    },
    {
      field: 'customerName',
      headerName: 'Customer name',
      width: 200,
      editable: false,
    },
    {
      field: 'creationDate',
      headerName: 'Creation date',
      width: 200,
      editable: false,
    },
    {
      field: 'guaranteeNumber',
      headerName: 'Guarantee number',
      width: 200,
      editable: false,
    },
    {
      field: 'serialNumber',
      headerName: 'Serial number',
      width: 200,
      editable: false,
    },
    {
      field: 'component',
      headerName: 'Component',
      width: 250,
      editable: false,
    },
    {
      field: 'shopName',
      headerName: 'Other owned products',
      width: 250,
      editable: false,
    },
    {
      field: 'city',
      headerName: 'Comments',
      width: 300,
      editable: false,
    },
    {
      field: 'language',
      headerName: 'Language',
      width: 150,
      editable: false,
    },
    {
      field: 'warrantyYears',
      headerName: 'Years',
      width: 150,
      editable: false,
    },
    {
      field: 'stars',
      headerName: 'Stars',
      width: 150,
      editable: false,
    },
    {
      field: 'find',
      headerName: 'Find',
      width: 400,
      editable: false,
      renderCell: (params: any) => {
        const obj = params.row.find
        const filtered =
          obj &&
          Object.keys(obj)
            .filter((k) => obj[k] && k !== 'sonstiges')
            .join(', ')
        const sonstiges =
          obj && params.row.find.sonstiges ? `sonstiges: ${params.row.find.sonstiges}` : null
        return <div>{[filtered, sonstiges].filter(Boolean).join(', ') || '-'}</div>
      },
    },
    {
      field: 'music',
      headerName: 'Listening preferences',
      width: 300,
      editable: false,
      renderCell: (params: any) => {
        const obj = params.row.music
        const filtered =
          obj &&
          Object.keys(obj)
            .filter((k) => obj[k])
            .join(', ')
        return <div>{filtered || '-'}</div>
      },
    },
    {
      field: 'acceptanceRegulationsCheckboxTwo',
      headerName: 'Opt In',
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params: any) => (
        <div>{params.row?.acceptanceRegulationsCheckboxTwo === true ? 'yes' : 'no'}</div>
      ),
    },
    {
      field: '',
      headerName: 'Delete',
      width: 120,
      editable: false,
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (params: any) => (
        <Button
          className="border-0 px-0 py-0"
          onClick={() => deleteGuaranteeItem(params.row?.guaranteeNumber)}
        >
          Delete
        </Button>
      ),
    },
  ]

  return (
    <div style={{ height: '800px', width: '100%' }}>
      {deleteErrorMessage ? (
        <div className="text-red-500 text-lg py-2">{deleteErrorMessage}</div>
      ) : (
        <></>
      )}
      <DataGrid
        density="compact"
        rows={guaranteesList}
        columns={columns}
        pageSize={21}
        disableSelectionOnClick
      />
    </div>
  )
}

export default GuaranteesTable
