import { useEffect, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RouteLink } from '../routes'
import { authUserLogoutAction } from '../../../auth/auth.actions'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import logo from '../../../assets/images/logo/LogoBurmester.svg'
import profileIcon from '../../../assets/icons/profileIcon.svg'
import { UserRole } from '../../../requests/user/user.domain'
import { Button } from '../button/Button'

export const AppHeader = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const userStore = useAppSelector((state) => state.user)
  const country = useMemo(() => userStore.userData?.country, [userStore.userData])

  const handleSelect = (event: React.FormEvent<HTMLSelectElement>) => {
    const selectedLanguage = event?.currentTarget.value
    if (selectedLanguage === 'EN') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('de')
    }
  }

  useEffect(() => {
    if (country) {
      if (
        country &&
        country !== 'Germany' &&
        country !== 'Deutschland' &&
        country !== 'Austria' &&
        country !== 'Österreich'
      ) {
        i18n.changeLanguage('en')
      } else {
        i18n.changeLanguage('de')
      }
    }
  }, [country, i18n])

  const logout = () => {
    dispatch(authUserLogoutAction())
    localStorage.removeItem('i18nextLng')
    navigate('/login')
  }

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 px-4">
        <div className="p-6">
          <Link to={RouteLink.MAIN}>
            <img src={logo} alt="Burmester" width={150} height={50} />
          </Link>
        </div>
        <div className="flex justify-end items-center py-6">
          <div className="flex items-center">
            {userStore.userData && userStore.userData.role === UserRole.ADMIN && (
              <Link to={RouteLink.ADMIN} className="mr-4">
                <Button>ADMIN PANEL</Button>
              </Link>
            )}
            {userStore.userData && (
              <>
                <div>
                  <div className="text-right">
                    {`${t('common.loggedAs')} `}
                    <span className="font-bold">{userStore.userData.fullName}</span>
                  </div>
                  <div className="text-right font-bold">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        logout()
                      }}
                    >
                      {t('common.logout')}
                    </button>
                  </div>
                </div>
                <Link to={RouteLink.PROFIL}>
                  <div className="ml-4 mr-4">
                    <img alt="icon for proile tile" src={profileIcon} />
                  </div>
                </Link>
              </>
            )}
            <select
              className="border p-2"
              value={i18n.language === 'en' ? 'EN' : 'DE'}
              onChange={handleSelect}
            >
              <option value="EN">{t('common.select.en')}</option>
              <option value="DE">{t('common.select.de')}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
