import { useState, useEffect, ChangeEvent, KeyboardEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { UserCredentals } from '../../requests/user/user.domain'
import { JWT_USER_TOKEN } from '../../config'
import { authUserStartAction } from '../../auth/auth.actions'
import { ErrorMessageBox } from '../common/messages/ErrorMessageBox'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { Button } from '../common/button/Button'
import { RouteLink } from '../common'
import { fetchUserStartAction } from '../../requests/user/user.actions'

export const LoginPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const authStore = useAppSelector((state) => state.auth)
  const [username, setUserName] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    const token = sessionStorage.getItem(JWT_USER_TOKEN)

    if (!token || !authStore || !authStore.token) {
      setPassword('')
    } else if (!authStore.inProgress) {
      dispatch(fetchUserStartAction())
      navigate(RouteLink.MAIN, { replace: true })
    }
  }, [authStore, dispatch, navigate])

  const onSubmit = () => {
    const credentials: UserCredentals = { username, password }
    dispatch(authUserStartAction(credentials))
  }

  const onEnterSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit()
    }
  }

  const onInputchange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'username') {
      setUserName(event.target.value)
    } else if (event.target.name === 'password') {
      setPassword(event.target.value)
    }
  }

  return (
    <div className="container">
      <div className="mb-6">
        <NewPageTitle topText={t('login.topText')} title={t('login.title')}>
          {t('login.pageDescribe')}
        </NewPageTitle>
      </div>
      <div className="flex justify-center pb-3">
        <div className="w-full mx-2 lg:mx-0 md:w-7/12 lg:w-5/12 xl:w-1/3">
          <div className="my-4">
            {authStore && (
              <div className="mt-2">
                {authStore.unauthorizedError && (
                  <ErrorMessageBox
                    errorState={authStore.unauthorizedError}
                    message={t('login.errors.wrongPassword') as string}
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="username"
              className="font-untitled-sans text-gray-10 text-lg tracking-wider pb-2"
            >
              {t('login.username')}
            </label>
            <input
              className="form-control shadow-custom h-14 p-3 focus:outline-none"
              name="username"
              value={username}
              onKeyDown={(e) => onEnterSubmit(e)}
              onChange={onInputchange}
            />
          </div>
          <div className="flex flex-col mt-8">
            <label
              htmlFor="password"
              className="font-untitled-sans text-gray-10 text-lg tracking-wider pb-2"
            >
              {t('login.password')}
            </label>
            <input
              className="form-control shadow-custom h-14 p-3 focus:outline-none"
              name={'password'}
              type={'password'}
              value={password}
              onKeyDown={(e) => onEnterSubmit(e)}
              onChange={onInputchange}
            />
          </div>
          <div className="flex justify-center mt-14">
            <Button
              onClick={() => {
                onSubmit()
              }}
            >
              {t('login.submit')}
            </Button>
          </div>
        </div>
      </div>
      <div className="text-center py-14">
        <p className="font-untitled-sans text-lg text-gray-10 uppercase font-semibold tracking-wide">
          {t('login.newAccountQuestion')}
        </p>
        <p className="font-untitled-sans text-xl text-gray-10">
          {`${t('login.contact')} `}
          <a
            href="https://www.burmester.de/UEber-Burmester/Kontakt/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-10 hover:underline"
          >
            {t('login.support')}
          </a>
          .
        </p>
      </div>
    </div>
  )
}
