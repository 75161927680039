import { Action } from 'redux'

import { UserCredentals } from '../requests/user/user.domain'

export const AUTH_USER_LOGOUT = 'USER/AUTH_USER_LOGOUT'
export const AUTH_USER_LOGOUT_IN_PROGRESS = 'USER/AUTH_USER_LOGOUT_IN_PROGRESS'
export const AUTH_USER_LOGOUT_DONE = 'USER/AUTH_USER_LOGOUT_DONE'

export const AUTH_USER_START = 'USER/AUTH_USER_START'
export const AUTH_USER_DONE = 'USER/AUTH_USER_DONE'
export const AUTH_USER_ERROR = 'USER/AUTH_USER_ERROR'

export const AUTH_SET_USER_DATA = 'USER/AUTH_SET_USER_DATA'
export const UNAUTHORIZED_ERROR = 'USER/UNAUTHORIZED_ERROR'
export const UNEXPECTED_ERROR = 'USER/UNEXPECTED_ERROR'

export interface IAuthenticateUserStartAction extends Action {
  type: typeof AUTH_USER_START
  payload: UserCredentals
}
export interface IAuthenticateUserLogoutAction extends Action {
  type: typeof AUTH_USER_LOGOUT
}

export interface IAuthenticateUserDoneAction extends Action {
  type: typeof AUTH_USER_DONE
  token: string
}

export interface IAuthenticateUserErrorAction extends Action {
  type: typeof AUTH_USER_ERROR
}

export const authUserLogoutAction = (): IAuthenticateUserLogoutAction => {
  return {
    type: AUTH_USER_LOGOUT,
  }
}

export const authUserLogoutInProgressAction = () => {
  return {
    type: AUTH_USER_LOGOUT_IN_PROGRESS,
  }
}

export const authUserLogoutDoneAction = () => {
  return {
    type: AUTH_USER_LOGOUT_DONE,
  }
}

export const authUserStartAction = (credentials: UserCredentals): IAuthenticateUserStartAction => {
  return {
    type: AUTH_USER_START,
    payload: credentials,
  }
}

export const authUserDoneAction = (token: string): IAuthenticateUserDoneAction => {
  return {
    type: AUTH_USER_DONE,
    token,
  }
}

export const authUserSetUnauthorizedError = (error: Error) => {
  return {
    type: UNAUTHORIZED_ERROR,
    error,
  }
}
export const authUserSetUnexpectedError = (error: Error) => {
  return {
    type: UNEXPECTED_ERROR,
    error,
  }
}
