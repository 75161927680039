import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AdminUserData, UserRole } from '../../../requests/user/user.domain'
import { Button } from '../../common/button/Button'

export const AdminUserForm = ({
  type = 'NEW',
  submitButtonLabel,
  createdPassword,
  onClick,
  onSubmit,
}: {
  submitButtonLabel: string
  onSubmit: (data: AdminUserData) => void
  createdPassword?: string | null
  type?: 'NEW' | 'EDIT'
  onClick?: () => void
}) => {
  const { register, handleSubmit, errors } = useFormContext()
  const { t } = useTranslation()
  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex my-10">
        <div className="w-1/2 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="username">
              Login *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="text"
              name="username"
              id="username"
              readOnly={type === 'EDIT'}
            />
            {errors.username && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/2 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="rmaCustomerId">
              Customer RmaId
            </label>
            <input
              className="p-3"
              ref={register}
              type="text"
              name="rmaCustomerId"
              id="rmaCustomerId"
            />
            {errors.username && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="role">
              {t('adminUserForm.role')} *
            </label>
            <select className="p-3" ref={register({ required: true })} name="role" id="role">
              <option value={UserRole.USER}>{UserRole.USER}</option>
              <option value={UserRole.ADMIN}>{UserRole.ADMIN}</option>
            </select>
            {errors.role && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex pb-10">
        <div className="w-1/3 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="email">
              Email *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="email"
              name="email"
              id="email"
            />
            {errors.email && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/3 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="fullName">
              {t('adminUserForm.fullName')} *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="text"
              name="fullName"
              id="fullName"
            />
            {errors.fullName && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/3">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="phone">
              {t('adminUserForm.phoneNumber')}
            </label>
            <input className="p-3" ref={register} type="text" name="phone" id="phone" />
          </div>
        </div>
      </div>
      <div className="flex mb-10">
        <div className="w-1/2 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="addressLine1">
              {t('adminUserForm.addressLine1')} *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="text"
              name="addressLine1"
              id="addressLine1"
            />
            {errors.addressLine1 && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="addressLine2">
              {t('adminUserForm.addressLine2')}
            </label>
            <input
              className="p-3"
              ref={register}
              type="text"
              name="addressLine2"
              id="addressLine2"
            />
          </div>
        </div>
      </div>
      <div className="flex mb-10">
        <div className="w-1/3 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="postcode">
              {t('adminUserForm.postalCode')} *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="number"
              name="postcode"
              id="postcode"
            />
            {errors.postcode && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/3 mr-4">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="city">
              {t('adminUserForm.city')} *
            </label>
            <input
              className="p-3"
              ref={register({ required: true })}
              type="text"
              name="city"
              id="city"
            />
            {errors.city && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
        <div className="w-1/3">
          <div className="flex flex-col">
            <label className="font-bold" htmlFor="country">
              {t('adminUserForm.country')} *
            </label>
            <select className="p-3" ref={register({ required: true })} name="country" id="country">
              <option value={'Deutschland'}>{t('adminUserForm.countries.Germany')}</option>
              <option value={'Österreich'}>{t('adminUserForm.countries.Austria')}</option>
              <option value={'USA'}>{t('adminUserForm.countries.USA')}</option>
              <option value={'England'}>{t('adminUserForm.countries.England')}</option>
              <option value={'Canada'}>{t('adminUserForm.countries.Canada')}</option>
            </select>
            {errors.country && (
              <div className="text-red-500 font-bold mt-2">{t('adminUserForm.requiredField')}</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex mb-10">
        <div className="w-1/2">
          {createdPassword && (
            <div className="flex flex-col">
              <label htmlFor="city"> {t('adminUserForm.createPassword')}: </label>
              <div className="font-weight-bold text-danger fs-1">{createdPassword}</div>
            </div>
          )}
        </div>
        <div className="w-1/2">
          <div className="flex justify-end">
            {createdPassword ? (
              <button className="button button-primary" onClick={onClick}>
                {t('adminUserForm.new')}
              </button>
            ) : (
              <Button type="submit">
                <div className="flex">
                  <div className="font-normal tracking-wider text-gray-15">{submitButtonLabel}</div>
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
