import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import userDataReducer from './requests/user/user.reducer'
import authReducer from './auth/auth.reducer'
import repairRequestReducer from './requests/repair-request/repairRequest.reducer'
import adminRepairRequestReducer from './admin/repair-request/adminRepairRequest.reducer'
import adminUserManagementReducer from './admin/user-management/adminUserManagement.reducer'
import faqRequestReducer from './requests/faq-request/faqRequest.reducer'
import customerRmaReducer from './requests/customer/customer.reducer'
import { watchForAuthUserLogoutAction, watchForAuthUserStartAction } from './auth/auth.saga'
import {
  watchForCreateRepairRequestStart,
  watchForFetchRepairRequestDetailsStart,
  watchForFetchRepairRequestsListStart,
} from './requests/repair-request/repairRequest.saga'
import {
  watchForFetchAdminRepairRequestDetailsStart,
  watchForFetchAdminRepairRequestsListStart,
  watchForPutAdminRepairRequestStatusStart,
  watchForDeleteAdminRepairRequestStart,
} from './admin/repair-request/adminRepairRequest.saga'
import { watchForFetchUserStartAction } from './requests/user/user.saga'
import { watchForAdminCreateUserStart } from './admin/user-management/adminUserManagement.saga'
import { getGuarantees } from './admin/guarantees/adminGuarantees.saga'
import adminGuaranteesReducer from './admin/guarantees/adminGuarantees.reducer'
import { watchForFetchAdminUserListStart } from './admin/user-management/user-list/adminUserList.saga'
import {
  watchForFetchAdminUserStart,
  watchForPatchAdminUserStart,
  watchForDeleteAdminUserStart,
} from './admin/user-management/user/adminUser.saga'
import { watchForResetPasswordAdminUserStart } from './admin/user-management/reset-password/resetPassword.saga'
import { watchFetchFaqList } from './requests/faq-request/faqRequest.saga'
import { watchFetchCustomerView } from './requests/customer/customer.saga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  auth: authReducer,
  user: userDataReducer,
  repairRequest: repairRequestReducer,
  adminRepairRequest: adminRepairRequestReducer,
  adminUserManagement: adminUserManagementReducer,
  adminGuaranteesList: adminGuaranteesReducer,
  faqList: faqRequestReducer,
  customerRmaList: customerRmaReducer,
})

const sagaMiddleware = createSagaMiddleware()

export const registerSagas = () => {
  ;[
    watchForAuthUserStartAction,
    watchForAuthUserLogoutAction,
    watchForFetchUserStartAction,
    watchForFetchRepairRequestsListStart,
    watchForCreateRepairRequestStart,
    watchForFetchRepairRequestDetailsStart,
    watchForFetchAdminRepairRequestsListStart,
    watchForFetchAdminRepairRequestDetailsStart,
    watchForPutAdminRepairRequestStatusStart,
    watchForDeleteAdminRepairRequestStart,
    watchForAdminCreateUserStart,
    watchForFetchAdminUserListStart,
    watchForFetchAdminUserStart,
    watchForPatchAdminUserStart,
    watchForDeleteAdminUserStart,
    watchForResetPasswordAdminUserStart,
    getGuarantees,
    watchFetchFaqList,
    watchFetchCustomerView,
  ].forEach((saga) => sagaMiddleware.run(saga))
}

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))
}

export type RootStore = ReturnType<typeof configureStore>
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = RootStore['dispatch']
