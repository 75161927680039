import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { AppFooter, AppHeader, RouteLink } from './views/common'
import { Menu } from './views/menu'
import {
  RepairRequestPage,
  RepairRequestThankYouPage,
  RepairRequestStatusPage,
} from './views/repair-request'
import { UserProfilePage } from './views/user-profil/UserProfilePage'
import { LoginPage } from './views/login'
import { PriceList } from './views/price-list/PriceList'
// import { AdminRepairsListPage } from './views/admin/repair-request-listing'
// import { AdminRepairItemEditPage } from './views/admin/repair-request-listing/AdminRepairItemEditPage'
import { AdminUserCreationPage } from './views/admin/user-management/AdminUserCreationPage'
import { AdminUserListPage } from './views/admin/user-management/AdminUserListPage'
import { AdminUserEditPage } from './views/admin/user-management/AdminUserEditPage'
import AdminGuaranteesList from './views/admin/guaranteesList/AdminGuaranteesList'
import Content from './views/common/content/Content'
import { RepairRequestMenu } from './views/repair-request/RepairRequestMenu'
import { BurmesterApp } from './views/burmester-app/BurmesterApp'
import { Contact } from './views/contact/Contact'
import { Bespoke } from './views/bespoke/Bespoke'
import ScrollToTop from './ScrollToTop'
import { ProtectedRoute } from './utils/ProtectedRoute'

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <AppHeader />
          <Content>
            <Routes>
              <Route path={RouteLink.LOGIN} element={<LoginPage />} />
              <Route
                path={RouteLink.MAIN}
                element={
                  <ProtectedRoute>
                    <Menu />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.REQUEST_SERVICE}
                element={
                  <ProtectedRoute>
                    <RepairRequestMenu />
                  </ProtectedRoute>
                }
              />
              <Route
                path={RouteLink.REQUEST_SERVICE_START}
                element={
                  <ProtectedRoute>
                    <RepairRequestPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${RouteLink.REQUEST_SERVICE_STATUS}/:id`}
                element={
                  <ProtectedRoute>
                    <RepairRequestStatusPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={RouteLink.REQUEST_SERVICE_THANKS}
                element={
                  <ProtectedRoute>
                    <RepairRequestThankYouPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.APP}
                element={
                  <ProtectedRoute>
                    <BurmesterApp />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.CONTACT}
                element={
                  <ProtectedRoute>
                    <Contact />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.PROFIL}
                element={
                  <ProtectedRoute>
                    <UserProfilePage />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.ADMIN}
                element={
                  <ProtectedRoute adminRoleRequired>
                    <AdminUserListPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.BESPOKE}
                element={
                  <ProtectedRoute>
                    <Bespoke />
                  </ProtectedRoute>
                }
              />

              <Route
                path={RouteLink.PRICE_LIST}
                element={
                  <ProtectedRoute>
                    <PriceList />
                  </ProtectedRoute>
                }
              />

              {/*<Route*/}
              {/*  path={RouteLink.ADMIN_REQUEST_SERVICE}*/}
              {/*  element={*/}
              {/*    <ProtectedRoute adminRoleRequired>*/}
              {/*      <AdminRepairsListPage />*/}
              {/*    </ProtectedRoute>*/}
              {/*  }*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={`${RouteLink.ADMIN_REQUEST_SERVICE}/:id`}*/}
              {/*  element={*/}
              {/*    <ProtectedRoute adminRoleRequired>*/}
              {/*      <AdminRepairItemEditPage />*/}
              {/*    </ProtectedRoute>*/}
              {/*  }*/}
              {/*/>*/}
              <Route
                path={RouteLink.ADMIN_GUARANTEE_LIST}
                element={
                  <ProtectedRoute adminRoleRequired>
                    <AdminGuaranteesList />
                  </ProtectedRoute>
                }
              />
              <Route
                path={RouteLink.ADMIN_USER_CREATE}
                element={
                  <ProtectedRoute adminRoleRequired>
                    <AdminUserCreationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={RouteLink.ADMIN_USERS}
                element={
                  <ProtectedRoute adminRoleRequired>
                    <AdminUserListPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${RouteLink.ADMIN_USERS}/:username`}
                element={
                  <ProtectedRoute adminRoleRequired>
                    <AdminUserEditPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Content>
          <AppFooter />
        </ScrollToTop>
      </Router>
    </>
  )
}

export default App
