import { put, call, takeLatest } from 'redux-saga/effects'

import {
  AUTH_USER_LOGOUT,
  AUTH_USER_START,
  authUserDoneAction,
  authUserLogoutDoneAction,
  authUserLogoutInProgressAction,
  authUserSetUnauthorizedError,
  IAuthenticateUserStartAction,
  UNAUTHORIZED_ERROR,
} from './auth.actions'
import { login } from './auth.service'

import { JWT_USER_TOKEN } from '../config'
import { fetchUserDoneAction } from '../requests/user/user.actions'

export function* watchForAuthUserStartAction() {
  yield takeLatest(AUTH_USER_START, callAuthenticateUser)
}

function* callAuthenticateUser(action: IAuthenticateUserStartAction) {
  try {
    const response: string = yield call(login, action.payload.username, action.payload.password)
    yield sessionStorage.setItem(JWT_USER_TOKEN, response)
    yield put(authUserDoneAction(response))
  } catch (error: unknown) {
    yield put(authUserSetUnauthorizedError(error as Error))
  }
}

export function* watchForAuthUserLogoutAction() {
  yield takeLatest(AUTH_USER_LOGOUT, logoutUser)
}

function* logoutUser() {
  yield put(authUserLogoutInProgressAction())
  yield sessionStorage.removeItem(JWT_USER_TOKEN)

  //TODO put clear redux state

  yield put(authUserLogoutDoneAction())
  yield put(fetchUserDoneAction(null))
}

export function* watchForUnauthorized() {
  yield takeLatest(UNAUTHORIZED_ERROR, callAuthenticateUser)
}
