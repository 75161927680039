import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

import { MENU_ITEM } from './constants'

import { RouteLink } from '../../views/common'
import { HorizontalMenuItem } from '../../views/common/horizontal-menu/HorizontalMenuItem'
import { Button } from '../../views/common/button/Button'

type Props = {
  current: MENU_ITEM
}
export const HorizontalAdminMenu = (props: Props) => {
  const [showBackButton, setShowBackButton] = useState<boolean>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const locationsToShowBackButton = ['/admin', '/admin/users', '/admin/guarantees/list']

  useEffect(() => {
    if (locationsToShowBackButton?.includes(currentPath)) {
      setShowBackButton(true)
    } else {
      setShowBackButton(false)
    }
  }, [currentPath])
  const goBack = () => navigate('/')
  return (
    <div className="pt-6">
      <div className="horizontal-menu">
        <HorizontalMenuItem
          isActive={props.current === MENU_ITEM.USERS}
          title={'Users'}
          link={RouteLink.ADMIN_USERS}
        />
        {/*<HorizontalMenuItem*/}
        {/*  isActive={props.current === MENU_ITEM.REPAIR_LIST}*/}
        {/*  title={'Reparatur Liste'}*/}
        {/*  link={RouteLink.ADMIN_REQUEST_SERVICE}*/}
        {/*/>*/}
        <HorizontalMenuItem
          isActive={props.current === MENU_ITEM.GUARANTEES_LIST}
          title={'Guarantees'}
          link={RouteLink.ADMIN_GUARANTEE_LIST}
        />
      </div>
      {showBackButton && (
        <div className="flex">
          <Button className="mb-6" onClick={goBack}>
            <div className="flex">
              <div className="mr-2 -ml-2">
                <ChevronLeftIcon className="h-4 w-4" />
              </div>
              <div className="font-normal tracking-wider text-gray-15">{t('common.back')}</div>
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
