import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'

import { AdminUserForm } from './AdminUserForm'

import { PageTitle, RouteLink } from '../../common'
import { AdminUserData } from '../../../requests/user/user.domain'
import {
  patchUserActionStart,
  fetchUserActionStart,
  deleteUserActionStart,
} from '../../../admin/user-management/user/adminUser.actions'
import { HorizontalAdminMenu } from '../../../admin/menu/HorizontalAdminMenu'
import { MENU_ITEM } from '../../../admin/menu/constants'
import usePrevious from '../../../utils/usePrevious'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { Button } from '../../common/button/Button'

export const AdminUserEditPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { username } = useParams<{ username?: string | undefined }>()
  const adminUserManagementState = useAppSelector((state) => state.adminUserManagement)
  const { t } = useTranslation()

  const methods = useForm()
  const { reset } = methods

  useEffect(() => {
    if (username) {
      dispatch(fetchUserActionStart(username))
    }
  }, [username, dispatch, reset])

  useEffect(() => {
    if (
      adminUserManagementState &&
      !adminUserManagementState.inProgress &&
      adminUserManagementState.editedUser
    ) {
      reset(adminUserManagementState.editedUser)
    }
  }, [adminUserManagementState, reset])

  const wasInProgress = usePrevious(adminUserManagementState.inProgress)
  useEffect(() => {
    if (
      wasInProgress &&
      !adminUserManagementState.editedUser &&
      !adminUserManagementState.inProgress &&
      !adminUserManagementState.error
    ) {
      navigate(RouteLink.ADMIN_USERS)
    }
  }, [wasInProgress, adminUserManagementState, navigate])

  const onSubmit = (data: AdminUserData) => {
    dispatch(patchUserActionStart(data))
  }

  const deleteUser = () => {
    if (!adminUserManagementState?.editedUser?.username) return
    const answer = window.confirm(`Do You really want to delete '${username}' user?`)
    answer && dispatch(deleteUserActionStart(adminUserManagementState.editedUser.username))
  }

  return (
    <div className="flex justify-center">
      <div className="w-3/4 px-4 bg-stone-100 border-l border-r border-stone-300">
        <div>
          <HorizontalAdminMenu current={MENU_ITEM.USERS} />
          <div className="container">
            <div className="col-12">
              <div className="row mb-5">
                <Link to={RouteLink.ADMIN_USERS}>
                  <Button>
                    <div className="flex">
                      <div className="mr-2 -ml-2">
                        <ChevronLeftIcon className="h-4 w-4" />
                      </div>
                      <div className="font-normal tracking-wider text-gray-15">
                        {t('common.back')}
                      </div>
                    </div>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <PageTitle value="Admin mode - Edit user" />
          {adminUserManagementState?.editedUser?.username && (
            <Button onClick={deleteUser}>
              <div className="flex">
                <div className="font-normal tracking-wider text-gray-15">{t('common.delete')}</div>
              </div>
            </Button>
          )}
        </div>
        <FormProvider {...methods}>
          <AdminUserForm
            type="EDIT"
            submitButtonLabel={t('adminUserForm.edit')}
            onSubmit={onSubmit}
          />
        </FormProvider>
      </div>
    </div>
  )
}
