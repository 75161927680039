import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADMIN_FETCH_USER_START,
  IAdminFetchUserStartAction,
  fetchUserActionInProgress,
  fetchUserActionDone,
  fetchUserActionError,
  ADMIN_PATCH_USER_START,
  IAdminUserPatchStartAction,
  patchUserActionDone,
  patchUserActionError,
  patchUserActionInProgress,
  ADMIN_DELETE_USER_START,
  IAdminUserDeleteStartAction,
  deleteUserActionInProgress,
  deleteUserActionDone,
  deleteUserActionError,
} from './adminUser.actions'
import { deleteAdminUser, fetchAdminUser, patchAdminUser } from './adminUser.services'

import { AdminUserData } from '../../../requests/user/user.domain'

export function* watchForFetchAdminUserStart() {
  yield takeLatest(ADMIN_FETCH_USER_START, callFetchAdminUser)
}

function* callFetchAdminUser(action: IAdminFetchUserStartAction) {
  try {
    yield put(fetchUserActionInProgress())
    const response: AdminUserData = yield call(fetchAdminUser, action.username)
    yield put(fetchUserActionDone(response))
  } catch (error: unknown) {
    yield put(fetchUserActionError(error as Error))
  }
}

export function* watchForPatchAdminUserStart() {
  yield takeLatest(ADMIN_PATCH_USER_START, callPatchAdminUser)
}

function* callPatchAdminUser(action: IAdminUserPatchStartAction) {
  try {
    yield put(patchUserActionInProgress())
    const { username } = action.userData
    const response: AdminUserData = yield call(patchAdminUser, username, action.userData)
    yield put(patchUserActionDone(response))
  } catch (error: unknown) {
    yield put(patchUserActionError(error as Error))
  }
}

export function* watchForDeleteAdminUserStart() {
  yield takeLatest(ADMIN_DELETE_USER_START, callDeleteAdminUser)
}

function* callDeleteAdminUser(action: IAdminUserDeleteStartAction) {
  try {
    yield put(deleteUserActionInProgress())
    yield call(deleteAdminUser, action.username)
    yield put(deleteUserActionDone(action.username))
  } catch (error: unknown) {
    yield put(deleteUserActionError(error as Error))
  }
}
