import { Action } from 'redux'

import { AdminUserData } from '../../../requests/user/user.domain'

export const ADMIN_USER_LIST_START = 'ADMIN/USER_MANAGEMENT/USER_LIST_START'
export const ADMIN_USER_LIST_IN_PROGRESS = 'ADMIN/USER_MANAGEMENT/USER_LIST_IN_PROGRESS'
export const ADMIN_USER_LIST_DONE = 'ADMIN/USER_MANAGEMENT/USER_LIST_DONE'
export const ADMIN_USER_LIST_ERROR = 'ADMIN/USER_MANAGEMENT/USER_LIST_ERROR'

export interface IAdminUserListStartAction extends Action {
  type: typeof ADMIN_USER_LIST_START
}
export const fetchUserListActionStart = (): IAdminUserListStartAction => {
  return {
    type: ADMIN_USER_LIST_START,
  }
}

export interface IAdminUserListInProgressAction extends Action {
  type: typeof ADMIN_USER_LIST_IN_PROGRESS
}
export const fetchUserListActionInProgress = (): IAdminUserListInProgressAction => {
  return {
    type: ADMIN_USER_LIST_IN_PROGRESS,
  }
}

export interface IAdminUserListDoneAction extends Action {
  type: typeof ADMIN_USER_LIST_DONE
  users: AdminUserData[]
}
export const fetchUserListActionDone = (users: AdminUserData[]): IAdminUserListDoneAction => {
  return {
    type: ADMIN_USER_LIST_DONE,
    users,
  }
}

export interface IAdminUserListErrorAction extends Action {
  type: typeof ADMIN_USER_LIST_ERROR
  error: Error
}
export const fetchUserListActionError = (error: Error): IAdminUserListErrorAction => {
  return {
    type: ADMIN_USER_LIST_ERROR,
    error,
  }
}
