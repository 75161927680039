import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADMIN_USER_LIST_START,
  fetchUserListActionInProgress,
  fetchUserListActionDone,
  fetchUserListActionError,
} from './adminUserList.actions'
import { fetchUserList } from './adminUserList.services'

import { AdminUserData } from '../../../requests/user/user.domain'

export function* watchForFetchAdminUserListStart() {
  yield takeLatest(ADMIN_USER_LIST_START, callAdminUserList)
}

function* callAdminUserList() {
  try {
    yield put(fetchUserListActionInProgress())
    const response: AdminUserData[] = yield call(fetchUserList)
    yield put(fetchUserListActionDone(response))
  } catch (error: unknown) {
    yield put(fetchUserListActionError(error as Error))
  }
}
