import { call, put, takeLatest } from 'redux-saga/effects'

import {
  ADMIN_CREATE_USER_START,
  createUserActionDone,
  createUserActionError,
  createUserActionInProgress,
  IAdminUserCreateStartAction,
} from './adminUserManagement.action'
import { createUserPost } from './adminUserManagement.service'

import { AdminUserData } from '../../requests/user/user.domain'

export function* watchForAdminCreateUserStart() {
  yield takeLatest(ADMIN_CREATE_USER_START, callAdminCreateNewUser)
}

function* callAdminCreateNewUser(action: IAdminUserCreateStartAction) {
  try {
    yield put(createUserActionInProgress())
    const response: AdminUserData = yield call(createUserPost, action.userData)
    yield put(createUserActionDone(response))
  } catch (error: unknown) {
    yield put(createUserActionError(error as Error))
  }
}
