import { Reducer } from 'redux'

import {
  ADMIN_CREATE_USER_DONE,
  ADMIN_CREATE_USER_ERROR,
  ADMIN_CREATE_USER_IN_PROGRESS,
  ADMIN_CLEAN_CREATED_USER,
  IAdminUserCreateDoneAction,
  IAdminUserCreateErrorAction,
  IAdminUserCreateInProgressAction,
  IAdminCleanCreatedUserAction,
} from './adminUserManagement.action'
import {
  ADMIN_USER_LIST_DONE,
  ADMIN_USER_LIST_ERROR,
  ADMIN_USER_LIST_IN_PROGRESS,
  IAdminUserListDoneAction,
  IAdminUserListErrorAction,
  IAdminUserListInProgressAction,
} from './user-list/adminUserList.actions'
import {
  ADMIN_FETCH_USER_START,
  ADMIN_FETCH_USER_DONE,
  ADMIN_FETCH_USER_ERROR,
  ADMIN_FETCH_USER_IN_PROGRESS,
  ADMIN_CLEAN_USER,
  IAdminFetchUserStartAction,
  IAdminFetchUserDoneAction,
  IAdminFetchUserErrorAction,
  IAdminFetchUserInProgressAction,
  IAdminCleanUserAction,
  ADMIN_PATCH_USER_DONE,
  ADMIN_PATCH_USER_ERROR,
  ADMIN_PATCH_USER_IN_PROGRESS,
  IAdminUserPatchDoneAction,
  IAdminUserPatchErrorAction,
  IAdminUserPatchInProgressAction,
  ADMIN_DELETE_USER_DONE,
  ADMIN_DELETE_USER_ERROR,
  ADMIN_DELETE_USER_IN_PROGRESS,
  IAdminUserDeleteDoneAction,
  IAdminUserDeleteErrorAction,
  IAdminUserDeleteInProgressAction,
} from './user/adminUser.actions'
import {
  ADMIN_RESET_USER_PASSWORD_START,
  ADMIN_RESET_USER_PASSWORD_IN_PROGRESS,
  ADMIN_RESET_USER_PASSWORD_DONE,
  ADMIN_RESET_USER_PASSWORD_ERROR,
  IAdminUserResetPasswordStartAction,
  IAdminUserResetPasswordInProgressAction,
  IAdminUserResetPasswordDoneAction,
  IAdminUserResetPasswordErrorAction,
} from './reset-password/resetPassword.actions'

import { AdminUserData, ResetPasswordData } from '../../requests/user/user.domain'

type AdminUserListActionTypes =
  | IAdminUserListInProgressAction
  | IAdminUserListDoneAction
  | IAdminUserListErrorAction
  | IAdminUserCreateInProgressAction
  | IAdminUserCreateDoneAction
  | IAdminUserCreateErrorAction
  | IAdminCleanCreatedUserAction
  | IAdminFetchUserStartAction
  | IAdminFetchUserInProgressAction
  | IAdminFetchUserDoneAction
  | IAdminFetchUserErrorAction
  | IAdminCleanUserAction
  | IAdminUserPatchDoneAction
  | IAdminUserPatchErrorAction
  | IAdminUserPatchInProgressAction
  | IAdminUserDeleteDoneAction
  | IAdminUserDeleteErrorAction
  | IAdminUserDeleteInProgressAction
  | IAdminUserResetPasswordStartAction
  | IAdminUserResetPasswordInProgressAction
  | IAdminUserResetPasswordDoneAction
  | IAdminUserResetPasswordErrorAction

export type AdminUserManagementStateType = {
  readonly newPassword: ResetPasswordData
  readonly currentUser: AdminUserData | null
  readonly newUser: AdminUserData | null
  readonly editedUser: AdminUserData | null
  readonly users: AdminUserData[]
  readonly inProgress: boolean
  readonly error?: Error | null
}

const initialState: AdminUserManagementStateType = {
  users: [],
  newPassword: {
    password: null,
    error: null,
  },
  currentUser: null,
  editedUser: null,
  newUser: null,
  inProgress: false,
  error: null,
}

const reducer: Reducer<AdminUserManagementStateType, AdminUserListActionTypes> = (
  state: AdminUserManagementStateType = initialState,
  action: AdminUserListActionTypes
) => {
  switch (action.type) {
    case ADMIN_USER_LIST_IN_PROGRESS:
    case ADMIN_FETCH_USER_IN_PROGRESS:
    case ADMIN_CREATE_USER_IN_PROGRESS:
    case ADMIN_PATCH_USER_IN_PROGRESS:
    case ADMIN_DELETE_USER_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }
    case ADMIN_USER_LIST_ERROR:
    case ADMIN_FETCH_USER_ERROR:
    case ADMIN_CREATE_USER_ERROR:
    case ADMIN_PATCH_USER_ERROR:
    case ADMIN_DELETE_USER_ERROR:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }
    // user list
    case ADMIN_USER_LIST_DONE:
      return {
        ...state,
        users: action.users,
        inProgress: false,
      }
    // patch user
    case ADMIN_FETCH_USER_START:
      return {
        ...state,
        editedUser: {} as AdminUserData,
      }
    case ADMIN_FETCH_USER_DONE:
      return {
        ...state,
        editedUser: action.user,
        inProgress: false,
      }
    case ADMIN_PATCH_USER_DONE:
      return {
        ...state,
        editedUser: null,
        inProgress: false,
      }
    case ADMIN_DELETE_USER_DONE:
      return {
        ...state,
        users: state.users.filter((user) => user.username !== action.username),
        inProgress: false,
      }
    // create new user
    case ADMIN_CREATE_USER_DONE:
      return {
        ...state,
        newUser: action.userData,
        inProgress: false,
      }
    case ADMIN_CLEAN_USER:
      return {
        ...state,
        editedUser: null,
      }
    case ADMIN_CLEAN_CREATED_USER:
      return {
        ...state,
        newUser: null,
      }
    case ADMIN_RESET_USER_PASSWORD_START:
      return {
        ...state,
        newPassword: {
          password: null,
          error: null,
        },
      }
    case ADMIN_RESET_USER_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        inProgress: true,
      }

    case ADMIN_RESET_USER_PASSWORD_DONE:
      return {
        ...state,
        newPassword: {
          password: action.password,
        },
        inProgress: false,
      }

    case ADMIN_RESET_USER_PASSWORD_ERROR:
      return {
        ...state,
        newPassword: {
          error: true,
        },
        inProgress: false,
      }
    default:
      return state
  }
}

export default reducer
