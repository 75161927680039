export enum RouteLink {
  ADMIN = '/admin',
  // ADMIN_REQUEST_SERVICE = '/admin/reparatur',
  ADMIN_GUARANTEE_LIST = '/admin/guarantees/list',
  ADMIN_USERS = '/admin/users',
  ADMIN_USER_CREATE = '/admin/users/create',
  APP = '/app',
  BESPOKE = '/bespoke',
  CONTACT = 'contact',
  LOGIN = 'login',
  MAIN = '/',
  PRICE_LIST = '/pricelist',
  PROFIL = '/profil',
  REQUEST_SERVICE = '/reparatur',
  REQUEST_SERVICE_START = '/reparatur-starten',
  REQUEST_SERVICE_STATUS = '/reparatur-status',
  REQUEST_SERVICE_THANKS = '/reparatur-danke',
}
