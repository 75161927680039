import { headers } from '../../utils/apiHeaders'
import handleResponse from '../../utils/handleApiResponse'
import { UserData } from '../../requests/user/user.domain'
import { BACKEND_URL } from '../../config'

export const URL_ADMIN_API_USERS = BACKEND_URL + '/api/admin/users'

export const createUserPost = (userData: UserData) => {
  const requestOptions = {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(userData),
  }

  return fetch(URL_ADMIN_API_USERS, requestOptions)
    .then(handleResponse)
    .then((createdUser) => createdUser)
}
