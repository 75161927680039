import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { RouteLink } from '../common'
import './RepairRequestPage.scss'
import {
  limitedDictionaryProductCategory,
  dictionaryProductList,
  Accessories,
  ProductCategory,
  RepairRequestStatus,
  RepairRequestStatusData,
} from '../../requests/repair-request/repairRequest.domain'
import { UserData } from '../../requests/user/user.domain'
import { fetchUserStartAction } from '../../requests/user/user.actions'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import NewPageTitle from '../common/new-page-title/NewPageTitle'
import { Button } from '../common/button/Button'
import { ErrorBox } from '../common/errorBox/ErrorBox'
import { headers, noContentTypeHeaders } from '../../utils/apiHeaders'
import { BACKEND_URL } from '../../config'
import { Spinner } from '../common/spinner/Spinner'

export const RepairRequestPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const userStore = useAppSelector((state) => state.user)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [sendingForm, setSendingForm] = useState(false)
  const [attachmentFiles, setUploadFiles] = useState<FileList | null>(null)
  const [invoiceFiles, setInvoiceFiles] = useState<FileList | null>(null)
  const [createdNewRma, setCreatedNewRma] = useState<boolean>(false)
  const [sentAttachmentPurchaseProof, setSentAttachmentPurchaseProof] = useState<boolean | null>(
    false
  )
  const [sentAttachmentFiles, setSentAttachmentFiles] = useState<boolean | null>(false)
  const [showErrorMessage, setShowErrorMessage] = useState<boolean | string>(false)
  const { register, handleSubmit, watch, errors } = useForm()

  const watchAccessories = watch('accessories')
  const watchedProductCategory = watch('productCategory')
  const productSerialInfo = t('repairRequest.form.productSerial.info')

  useEffect(() => {
    if (userStore && userStore.userData) {
      setUserData(userStore.userData)
    } else {
      dispatch(fetchUserStartAction())
    }
  }, [userStore, dispatch])

  useEffect(() => {
    if (
      createdNewRma &&
      (sentAttachmentPurchaseProof || sentAttachmentPurchaseProof === null) &&
      (sentAttachmentFiles || sentAttachmentFiles === null)
    ) {
      navigate(RouteLink.REQUEST_SERVICE_THANKS)
    }
  }, [createdNewRma, sentAttachmentPurchaseProof, sentAttachmentFiles, dispatch, navigate])

  const fieldInvoiceValidator = {
    maxFiles: (value: FileList) => !(value.length > 3),
    maxFileSize: (value: FileList) => !Array.from(value).some((file) => file.size > 10_000_000),
  }

  const filesUploadValidator = {
    maxFiles: (value: FileList) => !(value.length > 5),
    maxFileSize: (value: FileList) => !Array.from(value).some((file) => file.size > 25_000_000),
  }

  const sendAttachmentPurchaseProof = (wnmkey: string) => {
    if (!invoiceFiles) {
      return
    }
    const formData = new FormData()
    const URL_API_ADD_PURCHASE_PROOF = BACKEND_URL + `/api/easy-rma/rma/${wnmkey}/addPurchaseProof`
    for (let i = 0; i < invoiceFiles.length; i++) {
      formData.append('purchaseProof', invoiceFiles[i], invoiceFiles[i].name)
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: noContentTypeHeaders(),
      body: formData,
    }

    fetch(URL_API_ADD_PURCHASE_PROOF, requestOptions)
      .then((response) => {
        if (response.ok) {
          setSentAttachmentPurchaseProof(true)
        }
        if (!response.ok) {
          setSentAttachmentPurchaseProof(false)
          if (response.status === 403 || response.status === 401) {
            throw new Error('Unauthorized')
          }
          throw new Error('Request failed')
        }
      })
      .catch((err) => {
        setShowErrorMessage(true)
        throw new Error(err.message)
      })
  }

  const sendAttachmentFiles = (wnmkey: string) => {
    if (!attachmentFiles) {
      return
    }
    const formData = new FormData()
    const URL_API_ADD_PURCHASE_PROOF = BACKEND_URL + `/api/easy-rma/rma/${wnmkey}/addAttachments`
    for (let i = 0; i < attachmentFiles.length; i++) {
      formData.append('attachments', attachmentFiles[i], attachmentFiles[i].name)
    }

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: noContentTypeHeaders(),
      body: formData,
    }

    fetch(URL_API_ADD_PURCHASE_PROOF, requestOptions)
      .then((response) => {
        if (response.ok) {
          setSentAttachmentFiles(true)
        }
        if (!response.ok) {
          setSentAttachmentFiles(false)
          if (response.status === 403 || response.status === 401) {
            throw new Error('Unauthorized')
          }
          throw new Error('Request failed')
        }
      })
      .catch((err) => {
        setShowErrorMessage(true)
        throw new Error(err.message)
      })
  }

  type RepairRequestFormProps = {
    invoice?: FileList
    fileUpload?: FileList
    warrantyTask: boolean
    softwareVersion: string
    softwareEffort: string
    firmwareEffort: string
    requestId?: string
    paymentAgreement: boolean
    rightsAgreement: boolean
    productCategory: ProductCategory
    productModel: string
    productSerial: string
    productDescription: string
    currentStatus?: RepairRequestStatus
    statuses: RepairRequestStatusData[]
    requestDatetime: Date
    modificationDatetime: Date
    purchaseDate?: Date
    shippingOption?: string
    accessories?: string[]
    costEstimate1: boolean
    costEstimate2: boolean
    costEstimate3: boolean
    faqHint: boolean
    accessoriesText?: string
    versionSoftware?: string
  }

  const onSubmit = (data: RepairRequestFormProps) => {
    setSendingForm(true)
    setShowErrorMessage(false)
    const sellDateWithoutTime = data.purchaseDate?.toISOString().split('T')[0]
    const [firstName, ...lastName] = userData?.fullName?.split(' ') ?? ['', '']
    const surname =
      lastName.length === 1 ? lastName[0] : lastName.length > 1 ? lastName.join(' ') : ''
    const objToSend = {
      customerNo: userData?.rmaCustomerId ? userData.rmaCustomerId : '',
      companyName: '',
      customerReference: '',
      firstName: firstName,
      lastName: surname,
      street: userData?.addressLine1,
      streetNo: userData?.addressLine2,
      zipCode: userData?.postcode,
      city: userData?.city,
      country: userData?.country,
      phoneNumber: userData?.phone,
      mailAddress: userData?.email,
      productReason: 'Defekt',
      machineGroup: data.productCategory,
      productName: data.productModel,
      productSerial: data.productSerial,
      productDescription: data.productDescription,
      productAmount: '1',
      softwareVersion: data.versionSoftware,
      sellDate: sellDateWithoutTime,
      shippingOption: data.shippingOption,
      accessories: [
        {
          firstOption: data.accessories?.includes('1') ? 1 : 0,
          secondOption: data.accessories?.includes('2') ? 1 : 0,
          thirdOption: data.accessories?.includes('3') ? 1 : 0,
          fourthOption: data.accessories?.includes('4') ? 1 : 0,
        },
      ],
      accessoriesText: data.accessoriesText ? data.accessoriesText : '',
      costEstimate: [
        {
          firstOption: data.costEstimate1 ? 1 : 0,
          secondOption: data.costEstimate2 ? 1 : 0,
          thirdOption: data.costEstimate3 ? 1 : 0,
        },
      ],
      faqHint: data.faqHint ? 1 : 0,
      warrantyTask: data.warrantyTask ? 1 : 0,
      softwareEffort: data.softwareEffort ? 1 : 0,
      firmwareEffort: data.firmwareEffort ? 1 : 0,
    }

    const URL_API_NEW_RMA = BACKEND_URL + '/api/easy-rma/newrma'
    const requestOptions = {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify(objToSend),
    }
    fetch(URL_API_NEW_RMA, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 403 || response.status === 401) {
          throw Error('Unauthorized')
        }
      })
      .then((response) => {
        if (response.wnmkey && data.invoice && data.invoice?.length > 0) {
          sendAttachmentPurchaseProof(response.wnmkey)
        } else if (response.wnmkey && data.invoice?.length === 0) {
          setSentAttachmentPurchaseProof(null)
        }
        if (response.wnmkey && data.fileUpload && data.fileUpload.length > 0) {
          sendAttachmentFiles(response.wnmkey)
        } else if (response.wnmkey && data.fileUpload?.length === 0) {
          setSentAttachmentFiles(null)
        }
      })
      .then(() => {
        setCreatedNewRma(true)
      })
      .catch((err) => {
        setCreatedNewRma(false)
        setShowErrorMessage(true)
        throw Error(err.message)
      })
      .finally(() => {
        setSendingForm(false)
      })
    // dispatch(createRepairRequestStart(data))
  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement
    const files: FileList | null = inputElement.files
    setUploadFiles(files)
  }

  const handleInvoiceUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement
    const files: FileList | null = inputElement.files
    setInvoiceFiles(files)
  }

  return userData ? (
    <div className="pb-20 pt-8">
      <NewPageTitle
        topText={t('repairRequest.topText')}
        title={t('repairRequest.title')}
        backButton
      >
        {t('repairRequest.pageDescription')}
      </NewPageTitle>
      {Object.keys(errors).length > 0 ? (
        <ErrorBox
          title={t('repairRequest.form.error')}
          text={` ${t('repairRequest.form.validationMessage')}`}
        />
      ) : (
        <></>
      )}
      {sendingForm ? <Spinner text={t('menu.contactForm.sendingData')} /> : <></>}
      <form
        className="repair-form form grid grid-cols-6 gap-6"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <div className="col-span-6 lg:col-start-2 lg:col-span-4">
          <div className="grid grid-cols-2 gap-6 p-4">
            <div className="form-group ">
              <label htmlFor="productCategory">{`${t(
                'repairRequest.form.productCategory.label'
              )} *`}</label>
              <select
                className="form-control mt-4"
                ref={register({ required: true })}
                name="productCategory"
                defaultValue={undefined}
                value={undefined}
              >
                <option value="">{t('repairRequest.form.productCategory.placeholder')}</option>
                {limitedDictionaryProductCategory.map((key) => (
                  <option value={key} key={'productCategory-option-' + key}>
                    {t('repairRequest.form.productCategory.items.' + key)}
                  </option>
                ))}
              </select>
              {errors.productCategory && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.productCategory.error')}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="productModel">{`${t(
                'repairRequest.form.productModel.label'
              )} *`}</label>
              <select
                className="form-control mt-4"
                ref={register({ required: true })}
                name="productModel"
                defaultValue={undefined}
                value={undefined}
                disabled={!watchedProductCategory}
              >
                <option value={''}>{t('repairRequest.form.productModel.placeholder')}</option>
                {watchedProductCategory &&
                  dictionaryProductList.item(watchedProductCategory).map((v) => (
                    <option value={v} key={'productProduct-option-' + v}>
                      {v}
                    </option>
                  ))}
              </select>
              {errors.productModel && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.productModel.error')}
                </div>
              )}
            </div>
          </div>
          <div className="form-group p-4">
            <label htmlFor="productSerial">{`${t(
              'repairRequest.form.productSerial.label'
            )} *`}</label>
            <input
              className="form-control mt-4"
              ref={register({ required: true })}
              type="text"
              name="productSerial"
            />
            <p className="mt-2" dangerouslySetInnerHTML={{ __html: productSerialInfo }}></p>
            {errors.productSerial && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productSerial.error')}
              </div>
            )}
          </div>
          <div className="form-group p-4">
            <div className="form-check form-check-inline form-check-custom mt-5">
              <label className="form-check-label bold pt-0.5" htmlFor="warrantyTask">
                {t('repairRequest.form.warrantyTask.text')}
                <input
                  className="form-check-input"
                  ref={register}
                  type="checkbox"
                  id="warrantyTask"
                  name="warrantyTask"
                  value={1}
                />
                <span className="checkmark" />
              </label>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 p-4">
            <div className="form-group">
              <label htmlFor="purchaseDate">{`${t('repairRequest.form.purchaseDate')} *`}</label>
              <input
                className="form-control mt-4"
                ref={register({ required: true, valueAsDate: true })}
                type="date"
                name="purchaseDate"
              />
              {errors.purchaseDate?.type === 'required' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.requiredField')}
                </div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="invoice">{t('repairRequest.form.invoice.label')}</label>
              <input
                className="form-control bg-white mt-4"
                ref={register({ validate: fieldInvoiceValidator })}
                type="file"
                name="invoice"
                multiple
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInvoiceUpload(e)}
              />
              <p className="mt-2">{t('repairRequest.form.invoice.info')}</p>
              <p className="mt-2">{t('repairRequest.form.invoice.text')}</p>
              {errors.invoice?.type === 'maxFiles' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.maxFiles')}
                </div>
              )}
              {errors.invoice?.type === 'maxFileSize' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.maxFileSize')}
                </div>
              )}
              {errors.invoice?.type === 'fileType' && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.invoice.errors.fileType')}
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 p-4 gap-6">
            <div className="form-group">
              <label htmlFor="versionSoftware">
                {`${t('repairRequest.form.versionSoftware.label')} `}
              </label>
              <input
                className="form-control mt-4"
                ref={register}
                type="text"
                name="versionSoftware"
              />
            </div>
          </div>
          <p className="mt-2 ml-4 ">{t('repairRequest.form.versionSoftware.info')}</p>
          <div className="form-group p-4">
            <label htmlFor="AuthorizationToKindOfWork">{`${t(
              'repairRequest.form.AuthorizationToKindOfWork.label'
            )}`}</label>
            <div className="flex">
              <div className="form-check form-check-inline form-check-custom mr-5 mt-4">
                <label className="form-check-label pt-0.5" htmlFor="software-update">
                  {t('repairRequest.form.AuthorizationToKindOfWork.softwareEffort')}
                  <input
                    className="form-check-input"
                    ref={register}
                    id="software-update"
                    type="checkbox"
                    value={1}
                    name="softwareEffort"
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-check form-check-inline form-check-custom mr-5 mt-4">
                <label className="form-check-label pt-0.5" htmlFor="firmware-update">
                  {t('repairRequest.form.AuthorizationToKindOfWork.firmwareEffort')}
                  <input
                    className="form-check-input"
                    ref={register}
                    id="firmware-update"
                    type="checkbox"
                    value={2}
                    name="firmwareEffort"
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          </div>
          <div className="form-group p-4">
            <label htmlFor="productDescription">{`${t(
              'repairRequest.form.productDescription.label'
            )}:*`}</label>
            <textarea
              className="form-control form-textarea"
              rows={5}
              ref={register({ required: true, minLength: 20 })}
              name={'productDescription'}
            />
            {errors.productDescription?.type === 'required' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productDescription.errors.required')}
              </div>
            )}
            {errors.productDescription?.type === 'minLength' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.productDescription.errors.minLength')}
              </div>
            )}
          </div>
          {/*<div className="grid grid-cols-[160px,1fr] gap-6 p-3">*/}
          {/*  <div className="form-group">*/}
          {/*    <input type="file" id="file" name="file" hidden accept=".pdf,.jpg,.png,.mp4,.mov" />*/}
          {/*    <label*/}
          {/*      htmlFor="file"*/}
          {/*      className="bg-gray-5 hover:bg-gray-400 text-gray-10 py-2 px-4 border border-gray-10 rounded-2xl cursor-pointer inline-flex items-center"*/}
          {/*    >*/}
          {/*      {t('repairRequest.form.fileUpload')}*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*  <div className="form-group">*/}
          {/*    <p className="mt-2">{t('repairRequest.form.allowedFileFormats')}</p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="grid grid-cols-[400px,1fr] gap-6 p-3">
            <div className="form-group">
              {/*<label htmlFor="fileUpload">{t('repairRequest.form.fileUpload.label')}</label>*/}
              <input
                className="form-control bg-white"
                ref={register({ validate: filesUploadValidator })}
                type="file"
                name="fileUpload"
                multiple
                accept=".pdf,.jpg,.png,.mp4,.mov"
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUpload(e)}
              />
            </div>
            <div className="form-group">
              <p className="pt-4">{t('repairRequest.form.allowedFileFormats')}</p>
            </div>
            {errors.fileUpload?.type === 'maxFiles' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.fileUpload.errors.maxFiles')}
              </div>
            )}
            {errors.fileUpload?.type === 'maxFileSize' && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.fileUpload.errors.maxFileSize')}
              </div>
            )}
          </div>
          <div className="grid grid-cols-1 p-3">
            <p>{t('repairRequest.form.uploadPhotoMessage')}</p>
          </div>
          <div className="form-group p-4">
            <label>{t('repairRequest.form.accessories.title')}</label>
            <div className="flex flex-col lg:flex-row">
              {Object.entries(Accessories).map(([value], index) => (
                <div
                  key={index}
                  className="form-check form-check-inline form-check-custom mr-5 py-3"
                >
                  <label className="form-check-label" htmlFor={`accessories-${index}`}>
                    {t(`repairRequest.form.accessories.${value}`)}
                    <input
                      className="form-check-input"
                      ref={register}
                      id={`accessories-${index}`}
                      type="checkbox"
                      value={index + 1}
                      name="accessories"
                    />
                    <span className="checkmark" />
                  </label>
                </div>
              ))}
              {watchAccessories && watchAccessories.includes('4') && (
                <div className="col-auto flex-grow-1">
                  <input
                    className="form-control"
                    ref={register({ required: true })}
                    type="text"
                    name="accessoriesText"
                  />
                </div>
              )}
              {errors.accessoriesText && (
                <div className="text-red-500 font-bold ml-4 mt-3">
                  {t('repairRequest.form.accessories.error')}
                </div>
              )}
            </div>
          </div>
          <div className="form-group p-4">
            <label htmlFor="shippingOption">{`${t(
              'repairRequest.form.shippingOption.label'
            )}?*`}</label>
            <div>
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label pt-0.5" htmlFor="shippingOption-deviceUndamaged">
                  {t('repairRequest.form.shippingOption.description1')}
                  <input
                    className="form-check-input"
                    type="radio"
                    id="shippingOption-deviceUndamaged"
                    name="shippingOption"
                    ref={register({ required: true })}
                    value={1}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label pt-0.5" htmlFor="shippingOption-deviceDamaged">
                  {t('repairRequest.form.shippingOption.description2')}
                  <input
                    className="form-check-input"
                    type="radio"
                    id="shippingOption-deviceDamaged"
                    name="shippingOption"
                    ref={register({ required: true })}
                    value={2}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            {errors.shippingOption && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.newPackaging.error')}
              </div>
            )}
          </div>
          <div className="form-group p-4">
            <label>{t('repairRequest.form.additionalInfo.label')}</label>
            <div>
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label pt-0.5" htmlFor="costEstimate1">
                  {`*${t('repairRequest.form.additionalInfo.text1')}`}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="costEstimate1"
                    name="costEstimate1"
                    ref={register({ required: true })}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {errors.costEstimate1 && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.requiredField')}
                </div>
              )}
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label pt-0.5" htmlFor="costEstimate2">
                  {t('repairRequest.form.additionalInfo.text2')}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="costEstimate2"
                    name="costEstimate2"
                    ref={register}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="form-check form-check-inline form-check-custom mt-5">
                <label className="form-check-label pt-0.5" htmlFor="costEstimate3">
                  {`*${t('repairRequest.form.additionalInfo.text3')}`}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="costEstimate3"
                    name="costEstimate3"
                    ref={register({ required: true })}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              {errors.costEstimate3 && (
                <div className="text-red-500 font-bold mt-2">
                  {t('repairRequest.form.requiredField')}
                </div>
              )}
            </div>
          </div>
          <div className="form-group p-4">
            <label>{`${t('repairRequest.form.importantNotes.label')}:`}</label>
            <p className="mt-2">{t('repairRequest.form.importantNotes.text1')}</p>
            <p className="mt-2">{t('repairRequest.form.importantNotes.text2')}</p>
            <p className="mt-2">{t('repairRequest.form.importantNotes.text3')}</p>
            <p className="mt-2">{t('repairRequest.form.importantNotes.text4')}</p>
            <div className="form-check form-check-inline form-check-custom mt-5">
              <label className="form-check-label pt-0.5" htmlFor="faqHint">
                {`*${t('repairRequest.form.faqHint')}`}
                <input
                  className="form-check-input"
                  type="checkbox"
                  ref={register({ required: true })}
                  id="faqHint"
                  name="faqHint"
                />
                <span className="checkmark" />
              </label>
            </div>
            {errors.faqHint && (
              <div className="text-red-500 font-bold mt-2">
                {t('repairRequest.form.requiredField')}
              </div>
            )}
          </div>
          <div className="grid grid-cols-[400px,1fr] gap-6 p-3">
            <div className="form-group">
              <div>{t('common.requiredFields')}</div>
            </div>
            <div className="form-group">
              <div className="text-right">
                <Button
                  disabled={sendingForm}
                  className={sendingForm ? 'opacity-50 cursor-not-allowed' : ''}
                  type="submit"
                >
                  {t('repairRequest.form.submitButton')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {showErrorMessage ? (
        <ErrorBox
          title={t('repairRequest.error')}
          text={` ${t('repairRequest.errorMessage')}`}
          onClick={() => setShowErrorMessage(false)}
        />
      ) : (
        <></>
      )}
    </div>
  ) : null
}
