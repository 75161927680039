import { AdminUserData } from '../user/user.domain'
import { Dictionary, IDictionary } from '../../utils/dictionary'

export type DictionaryItem = {
  value: string
  subvalue?: string
  description?: string
}

export enum RepairRequestStatus {
  DATA_SENT = 'DATA_SENT',
  REQUEST_TAKEN = 'REQUEST_TAKEN',
  PARCEL_RECEIVED = 'PARCEL_RECEIVED',
  COST_ESTIMATION = 'COST_ESTIMATION',
  REPAIR_FINISHED = 'REPAIR_FINISHED',
  RECEIPT = 'RECEIPT',
  PARCEL_SENT_BACK = 'PARCEL_SENT_BACK',
}

export const dictionaryRepairRequestStatus: IDictionary<DictionaryItem> = new Dictionary([
  [
    RepairRequestStatus.DATA_SENT.toString(),
    {
      value: 'dictionaryRepairRequestStatus.dataSent.display.value',
      subvalue: 'dictionaryRepairRequestStatus.dataSent.display.subvalue',
      description: 'dictionaryRepairRequestStatus.dataSent.display.description',
    },
  ],
  [
    RepairRequestStatus.REQUEST_TAKEN.toString(),
    {
      value: 'dictionaryRepairRequestStatus.requestTaken.display.value',
      subvalue: 'dictionaryRepairRequestStatus.requestTaken.display.subvalue',
      description: 'dictionaryRepairRequestStatus.requestTaken.display.description',
    },
  ],
  [
    RepairRequestStatus.PARCEL_RECEIVED.toString(),
    {
      value: 'dictionaryRepairRequestStatus.parcelReceived.value',
      subvalue: 'dictionaryRepairRequestStatus.parcelReceived.subvalue',
      description: 'dictionaryRepairRequestStatus.parcelReceived.description',
    },
  ],
  [
    RepairRequestStatus.COST_ESTIMATION.toString(),
    {
      value: 'dictionaryRepairRequestStatus.costEstimation.display.value',
      description: 'dictionaryRepairRequestStatus.costEstimation.display.description',
    },
  ],
  [
    RepairRequestStatus.REPAIR_FINISHED.toString(),
    {
      value: 'dictionaryRepairRequestStatus.repairFinished.value',
      subvalue: 'dictionaryRepairRequestStatus.repairFinished.subvalue',
      description: 'dictionaryRepairRequestStatus.repairFinished.description',
    },
  ],
  [
    RepairRequestStatus.RECEIPT.toString(),
    {
      value: 'dictionaryRepairRequestStatus.receipt.display.value',
      description: 'dictionaryRepairRequestStatus.receipt.display.description',
    },
  ],
  [
    RepairRequestStatus.PARCEL_SENT_BACK.toString(),
    {
      value: 'dictionaryRepairRequestStatus.parcelSentBack.value',
      description: 'dictionaryRepairRequestStatus.parcelSentBack.description',
    },
  ],
])

export enum ProductCategory {
  CD_PLAYER = 'CD_PLAYER',
  DVD_PLAYER = 'DVD_PLAYER',
  DA_CONVERTER = 'DA_CONVERTER',
  MUSICCENTER = 'MUSICCENTER',
  NETWORK_PRODUCT = 'NETWORK_PRODUCT',
  FULL_AMPLIFIER = 'FULL_AMPLIFIER',
  ACTIVE_CROSSOVER = 'ACTIVE_CROSSOVER',
  PASSIV_CROSSOVER = 'PASSIV_CROSSOVER',
  'SYSTEM_909' = 'SYSTEM_909',
  REMOTE_CONTROL = 'REMOTE_CONTROL',
  RACK = 'RACK',
  RECORD_PLAYER = 'RECORD_PLAYER',
  PRE_AMPLIFIER = 'PRE_AMPLIFIER',
  PREAMP_PHONO = 'PREAMP_PHONO',
  AMPLIFIER = 'AMPLIFIER',
  AMPLIFIER_TWO_CHANNEL = 'AMPLIFIER_TWO_CHANNEL',
  AMPLIFIER_THREE_CHANNEL = 'AMPLIFIER_THREE_CHANNEL',
  AMPLIFIER_FIVE_CHANNEL = 'AMPLIFIER_FIVE_CHANNEL',
  AMPLIFIER_MONO = 'AMPLIFIER_MONO',
  POWER_CONDITIONER = 'POWER_CONDITIONER',
  SPEAKER = 'SPEAKER',
  COMPACT_SPEAKER = 'COMPACT_SPEAKER',
  CENTER_SPEAKER = 'CENTER_SPEAKER',
  SUBWOOFER = 'SUBWOOFER',
  AMBIENCE_SPEAKER = 'AMBIENCE_SPEAKER',
  INWALL_SPEAKER = 'INWALL_SPEAKER',
  TUNER = 'TUNER',
  DAC = 'DAC',
  SURROUND_PROCESSOR = 'SURROUND_PROCESSOR',
  ALL_IN_ONE = 'ALL_IN_ONE',
  // XT = 'XT',
}

export const dictionaryProductCategory: IDictionary<string> = new Dictionary([
  [ProductCategory.CD_PLAYER.toString(), 'CD-Player'],
  [ProductCategory.DVD_PLAYER.toString(), 'DVD-Player'],
  [ProductCategory.DA_CONVERTER.toString(), 'D/A Wandler'],
  [ProductCategory.MUSICCENTER.toString(), 'Musiccenter'],
  [ProductCategory.NETWORK_PRODUCT.toString(), 'Netzwerkspieler'],
  [ProductCategory.FULL_AMPLIFIER.toString(), 'Vollverstärker'],
  [ProductCategory.PRE_AMPLIFIER.toString(), 'Vorverstärker'],
  [ProductCategory.AMPLIFIER.toString(), 'Endverstärker'],
  [ProductCategory.SPEAKER.toString(), 'Lautsprecher'],
  [ProductCategory.RECORD_PLAYER.toString(), 'Plattenspieler'],
  [ProductCategory.TUNER.toString(), 'Tuner'],
  [ProductCategory.SURROUND_PROCESSOR.toString(), 'Surround Prozessor'],
  [ProductCategory.ACTIVE_CROSSOVER.toString(), 'Aktive Frequenzweiche'],
  [ProductCategory.PASSIV_CROSSOVER.toString(), 'Passive Frequenzweiche'],
  [ProductCategory.SYSTEM_909.toString(), '909 (System)'],
  [ProductCategory.POWER_CONDITIONER.toString(), 'Power Conditioner'],
  [ProductCategory.REMOTE_CONTROL.toString(), 'Fernbedienung'],
  [ProductCategory.RACK.toString(), 'Rack'],
  [ProductCategory.PREAMP_PHONO.toString(), 'Phono-Vorverstärker'],
  [ProductCategory.AMPLIFIER_TWO_CHANNEL.toString(), '2-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_THREE_CHANNEL.toString(), '3-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_FIVE_CHANNEL.toString(), '5-/6-Kanal Endstufe'],
  [ProductCategory.AMPLIFIER_MONO.toString(), 'Endverstärker (Mono)'],
  [ProductCategory.INWALL_SPEAKER.toString(), 'InWall Lautsprecher'],
  [ProductCategory.COMPACT_SPEAKER.toString(), 'Kompaktlautsprecher'],
  [ProductCategory.CENTER_SPEAKER.toString(), 'Center Speaker'],
  [ProductCategory.SUBWOOFER.toString(), 'Subwoofer'],
  [ProductCategory.AMBIENCE_SPEAKER.toString(), 'Ambience Lautsprecher'],
  [ProductCategory.DAC.toString(), 'DA-Converter'],
  [ProductCategory.ALL_IN_ONE.toString(), 'All-in-One'],
  // [ProductCategory.XT.toString(), 'XT'],
])

export const limitedDictionaryProductCategory: IDictionary<string> = new Dictionary([
  [ProductCategory.CD_PLAYER.toString(), 'CD-Player'],
  [ProductCategory.DVD_PLAYER.toString(), 'DVD-Player'],
  [ProductCategory.DA_CONVERTER.toString(), 'D/A converter'],
  [ProductCategory.MUSICCENTER.toString(), 'musiccenter'],
  [ProductCategory.NETWORK_PRODUCT.toString(), 'network player'],
  [ProductCategory.FULL_AMPLIFIER.toString(), 'integrated amplifier'],
  [ProductCategory.PRE_AMPLIFIER.toString(), 'preamplifier'],
  [ProductCategory.AMPLIFIER.toString(), 'power amplifier'],
  [ProductCategory.SPEAKER.toString(), 'loudspeaker'],
  [ProductCategory.RECORD_PLAYER.toString(), 'turntable'],
  [ProductCategory.TUNER.toString(), 'tuner'],
  [ProductCategory.SURROUND_PROCESSOR.toString(), 'surround processor'],
  [ProductCategory.ACTIVE_CROSSOVER.toString(), 'active crossover'],
  [ProductCategory.PASSIV_CROSSOVER.toString(), 'passiv crossover'],
  [ProductCategory.SYSTEM_909.toString(), '909 (system)'],
  [ProductCategory.POWER_CONDITIONER.toString(), 'power conditioner'],
  [ProductCategory.REMOTE_CONTROL.toString(), 'remote control'],
  [ProductCategory.RACK.toString(), 'Rack'],
])

export const dictionaryProductList: IDictionary<string[]> = new Dictionary([
  [
    ProductCategory.CD_PLAYER.toString(),
    [
      '001',
      '006',
      '052',
      '061',
      '069',
      '089',
      '102',
      '916',
      '917',
      '937',
      '939 (CD-Laufwerk / CD drive)',
      '941',
      '963',
      '969 (CD-Laufwerk / CD drive)',
      '979 (CD-Laufwerk / CD drive)',
      '992',
      'Concerto CD',
    ],
  ],
  [ProductCategory.DVD_PLAYER.toString(), ['033']],
  [ProductCategory.DA_CONVERTER.toString(), ['113', '870', '920', '970', '980']],
  [
    ProductCategory.MUSICCENTER.toString(),
    ['111', '151 MK1', '151 MK2', '161 All-in-one', 'Phase 3 (161 All-In-One + B15)'],
  ],
  [
    ProductCategory.FULL_AMPLIFIER.toString(),
    ['032', '051', '082', '940', '959', '991', '101', '232'],
  ],
  [
    ProductCategory.PRE_AMPLIFIER.toString(),
    [
      '077',
      '035',
      '011',
      '088',
      '099',
      '100 (Phono)',
      '785',
      '777',
      '808 MK1',
      '808 MK2',
      '808 MK3',
      '808 MK5',
      '838 (Phono)',
      '846',
      '870 MK1',
      '870 MK2',
      '877 MK1',
      '877 MK2',
      '897',
      '935 MK1',
      '935 MK2',
      'Concerto PRE',
    ],
  ],
  [ProductCategory.RECORD_PLAYER.toString(), ['175', '217']],
  [
    ProductCategory.AMPLIFIER.toString(),
    [
      '036',
      '037 (3-Kanal / 3-channel)',
      '039 (5-/6-Kanal / 5-6-channel)',
      '159 (MONO)',
      '216',
      '218',
      '828 MK1 (MONO)',
      '828 MK2 (MONO)',
      '850 (MONO)',
      '878',
      '909 MK2',
      '909 MK3',
      '909 MK5',
      '911 MK1',
      '911 MK2',
      '911 MK3',
      '933 MK1',
      '933 MK2',
      '956 MK1',
      '956 MK2',
      'Concerto POW',
    ],
  ],
  [
    ProductCategory.SPEAKER.toString(),
    [
      '949 MK1',
      '949 MK2',
      '961 MK1',
      '961 MK2',
      '961 MK3',
      '975 Leo',
      '995 MK1',
      '995 MK2',
      '995 MK3',
      'B97',
      'B99',
      'B10',
      'B15',
      'B18',
      'B22',
      'B28',
      'B20',
      'B25',
      'B30',
      'B38',
      'B50',
      'B80 MK1',
      'B80 MK2',
      'B100',
      'BC350',
      'BC150',
      'BA71',
      'BA31',
      'C8',
      'S8',
      'InWall Satellite',
      'InWall Center',
      'InWall Woofer',
    ],
  ],
  [
    ProductCategory.TUNER.toString(),
    ['053', '915', '931', '941 (optional)', '978', '993', 'Concerto TUN'],
  ],
  [ProductCategory.SURROUND_PROCESSOR.toString(), ['007', '057', '087']],
  [ProductCategory.ACTIVE_CROSSOVER.toString(), ['866', '949', '961']],
  [
    ProductCategory.PASSIV_CROSSOVER.toString(),
    ['949 standard', '949 (911 Gehäuse / 911 housing)', '961 standard'],
  ],
  [ProductCategory.SYSTEM_909.toString(), ['909 System']],
  [ProductCategory.POWER_CONDITIONER.toString(), ['948', '038']],
  [ProductCategory.NETWORK_PRODUCT.toString(), ['150']],
  [
    ProductCategory.REMOTE_CONTROL.toString(),
    [
      'Basic Fernbedienung / Basic remote control',
      'prog. Fernbedienung / progr. remote control',
      '003',
      '005',
      '007',
      '067',
      '067 (Aluminium Gehäuse / metal case)',
      'S8',
    ],
  ],
  [ProductCategory.RACK.toString(), ['V1', 'V3', 'V5', 'V6', 'V-Base']],
])

export enum Accessories {
  Cable = 'Kabel',
  Puck = 'Puck',
  Medium = 'Tonträger',
  Other = 'Anderes',
}

export type RepairRequestStatusData = {
  id?: number
  type: RepairRequestStatus
  files?: string[]
  additionalInfo?: string
  additionalInfoLink?: string
}

export type RepairRequestStatusForm = {
  type: RepairRequestStatus
  file?: FileList
  additionalInfo?: string
  additionalInfoLink?: string
}

export type RepairRequestData = {
  requestId?: string
  serialNumber: string
  purchaseDate?: string
  productModel: string
  newPackaging: boolean
  productCategory: ProductCategory
  requestDescription: string
  paymentAgreement: boolean
  rightsAgreement: boolean
  fullName?: string
  email?: string
  accessories: string
  read?: boolean
  lastModificationUser?: AdminUserData
  versionSoftware: string
  // warrantyClaim: boolean
  currentStatus?: RepairRequestStatus
  statuses?: RepairRequestStatusData[]
  requestDatetime?: Date
  modificationDatetime?: Date
}

export type RepairRequestForm = {
  requestId?: string
  serialNumber: string
  purchaseDate?: Date
  productModel: string
  newPackaging: 0 | 1
  requestDescription: string
  paymentAgreement: boolean
  rightsAgreement: boolean
  productCategory: ProductCategory
  invoice?: FileList
  accessories: string[]
  accessoriesOtherDescription?: string
  versionSoftware: string
  // warrantyClaim: boolean
  currentStatus?: RepairRequestStatus
  statuses?: RepairRequestStatusData[]
  requestDatetime?: Date
  modificationDatetime?: Date
}
