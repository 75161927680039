import { BACKEND_URL } from '../../config'
import { headers } from '../../utils/apiHeaders'
import handleResponse from '../../utils/handleApiResponse'

export const fetchCurrentUser = () => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(`${BACKEND_URL}/api/users/current`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user
    })
}

export const fetchCurrentAdminUser = () => {
  const requestOptions = {
    method: 'GET',
    headers: headers(),
  }

  return fetch(`${BACKEND_URL}/api/admin/users/current`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user
    })
}
